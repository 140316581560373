import React from "react";
import { Row, Col } from "react-bootstrap";

const SpecialPrice = ({ special, className, leftColClass, rightColClass }) => {
  const saleType = special.sale_type;

  const formatPercent = (value) => {
    const pct = value.substring(0, 4);
    // Adds a zero if the percent ends with a period
    return pct.endsWith(".") ? pct + 0 : pct;
  };

  const LeftSideWrapper = ({ heading, children }) => (
    <div>
      <div>
        <span className="text-uppercase text-blue-600 font-weight-bold text-sm tracking-wider">
          {heading}
        </span>
      </div>
      {children}
    </div>
  );

  const RightSideWrapper = ({ heading, children }) => {
    if (saleType === "buy" && special.msrp === special.sale_price) {
      return null;
    } else {
      return (
        <Col className={rightColClass}>
          <div>
            <div>
              <span className="text-uppercase text-blue-600 font-weight-bold text-sm tracking-wider">
                {heading}
              </span>
            </div>
            {children}
          </div>
        </Col>
      );
    }
  };

  const LeftSideContents = () => {
    if (saleType === "lease") {
      return (
        <>
          <div className="d-flex align-items-end">
            <div className="d-flex align-items-start">
              <div>
                <span className="text-xs">$</span>
              </div>
              <span className="text-2xl font-weight-semibold">
                {Number(special.sale_payment).toLocaleString()}
              </span>
            </div>
            <div className="d-flex mb-1 ml-1">
              <span className="text-gray-800">/ month</span>
            </div>
          </div>
          <p class="text-xs font-weight-semibold leading-none text-gray-500">
            On approved credit
          </p>
        </>
      );
    } else if (saleType === "buy") {
      return (
        <>
          <div className="d-flex align-items-end">
            <div className="d-flex align-items-start">
              <div>
                <span className="text-xs">$</span>
              </div>
              <span className="text-2xl font-weight-semibold">
                {Number(special.sale_price).toLocaleString()}
              </span>
            </div>
          </div>
          <p class="text-xs font-weight-semibold leading-none text-gray-500">
            Plus taxes and fees
          </p>
        </>
      );
    } else if (saleType == "rebate") {
      return (
        <div className="d-flex align-items-end">
          <div className="d-flex align-items-start">
            {special.rebate_amount ? (
              <>
                <div>
                  <span className="text-xs">$</span>
                </div>
                <span className="text-2xl font-weight-semibold">
                  {Number(special.rebate_amount).toLocaleString()}
                </span>
              </>
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
      );
    } else if (saleType === "apr") {
      return (
        <>
          <div className="d-flex align-items-end">
            <div className="d-flex align-items-start">
              <span className="text-2xl font-weight-semibold">
                {formatPercent(special.sale_apr)}%
              </span>
            </div>
          </div>
          <p class="text-xs font-weight-semibold leading-none text-gray-500">
            Plus taxes and fees
          </p>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex align-items-end">
            <div className="d-flex align-items-start">
              <div>
                <span className="text-xs">$</span>
              </div>
              <span className="text-2xl font-weight-semibold">
                {Number(special.sale_payment).toLocaleString()}
              </span>
            </div>
            <div className="d-flex mb-1 ml-1">
              <span className="text-gray-800">/ month</span>
            </div>
          </div>
          <p class="text-xs font-weight-semibold leading-none text-gray-500">
            Plus taxes and fees
          </p>
          {special.approved_credit && (
            <p
              class="text-xs font-weight-semibold leading-none text-gray-500"
              style={{ marginTop: "-0.75rem" }}
            >
              On approved credit
            </p>
          )}
        </>
      );
    }
  };

  const RightSideContents = () => {
    return saleType === "lease" ||
      saleType === "apr" ||
      saleType === "finance" ? (
      <div className="d-flex align-items-end">
        <div>
          <span className="text-2xl font-weight-semibold">
            {special.sale_term}
          </span>
        </div>
        <div className="d-flex mb-1 ml-1">
          <span className="text-gray-800">months</span>
        </div>
      </div>
    ) : (
      <div className="d-flex align-items-start text-slate-500">
        <div>
          <span className="text-xs">$</span>
        </div>
        <span className="text-lg font-weight-light">
          {Number(special.msrp).toLocaleString()}
        </span>
      </div>
    );
  };

  const setRightSideHeading = () => {
    return saleType === "lease"
      ? "Lease Term"
      : saleType === "apr" || saleType === "finance"
      ? "APR Term"
      : special.vehicle.new
      ? "MSRP"
      : "Price";
  };

  return (
    <div className={className}>
      <Row>
        <Col className={leftColClass}>
          <LeftSideWrapper
            heading={
              special.sale_type === "lease"
                ? "Lease"
                : special.sale_type === "apr"
                ? "APR"
                : special.sale_type === "rebate"
                ? "Rebate"
                : special.sale_type === "buy" && special.vehicle.new === false
                ? "Sale Price"
                : "Deal"
            }
          >
            <LeftSideContents />
          </LeftSideWrapper>
        </Col>
        {/* removed column tags here because they need to be managed conditionally inside the RightSideWrapper */}
        <RightSideWrapper heading={setRightSideHeading()}>
          <RightSideContents />
        </RightSideWrapper>
      </Row>
    </div>
  );
};

export default SpecialPrice;
